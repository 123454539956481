<template>
<middleware-sidebar-outline
  lazy
  :uuid="uuid"
  :middlewareID="middlewareID"
  :middlewareType="middlewareType"
  @close="$emit('close')"
  @shown="$emit('shown')"
  @saveMiddleware="buildAgents"
  v-model="variables"
>
  <div v-if="mongoUpdateData.connection">
    <b-row class="mt-1 border rounded shadow mx-0 py-75">
     
      
      <b-col cols="6">
        <connection-register-input class="my-50" :connectionTypeID="3" v-model="mongoUpdateData.connection"/>
      </b-col>

      
      <b-col v-for="pos in renderCustomInputList" :key="pos.label" :cols="pos.cols">
        <b-collapse :visible="!pos.discard_if_uri || !uri_active">
          <div>
            <custom-input
              v-if="mongoUpdateData[pos.label] != undefined && variables"
              label="Destiny"
              :id="`custom-input-${pos.label}`"
              class="my-50"
              :possibleValues="variables"
              v-model="mongoUpdateData[pos.label].register_1"
            >
              <template #label>
                <span class="text-capitalize">
                  {{ pos.label }} 
                </span>
              </template>
            </custom-input>
            <div v-else class="mb-2">
              <b-skeleton class="mb-25" width="25%" height="12px"/>
              <b-skeleton class="mb-25" width="100%" height="37px"/>
              <b-skeleton class="mb-25" width="25%" height="14px"/>
            </div>
          </div>
        </b-collapse>
      </b-col>

      
    </b-row>

    <b-row>
      <b-col cols="6" class="pr-25">
        <div class="mt-1 border rounded shadow">
          <h4 class="m-75" for="update-values">Values <helper-tooltip tooltipPlacement="right" size="15" innerText="eopae" innter/></h4>
          <monaco
            language="json"
            height="600px"
            v-model="mongoUpdateData.document.register_1.value"
          />
        </div>
      </b-col>
      <b-col cols="6" class="pl-25">
        <div class="mt-1 border rounded shadow">
          <h4 class="m-75" for="logic-expression-where">Where <helper-tooltip tooltipPlacement="right" size="15" innerText="eopae" innter/></h4>
          <monaco
            language="json"
            height="600px"
            v-model="mongoUpdateData.filter.register_1.value"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</middleware-sidebar-outline>  

</template>

<script>
import { 
  BPopover,
  BIcon,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BContainer,
  BSidebar,
  BSpinner,
  BTabs,
  BTab,
  BCardText,
  BCardBody,
  BCard,
  BAvatar,
  BSkeleton,
  BFormCheckbox,
  BCollapse
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VariablesPanel from "@/views/pages/middleware/MiddlewareManagement/Components/VariablesPanel.vue";
import { makeToast } from '@/layouts/components/Popups'
import Source from '@/custom/class/Agent/Source'
import ActionsReorderList from '@/layouts/components/Transmission/Middleware/Agent/ActionsReorderList.vue'
import CustomInput from "@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue";
import HelperTooltip from '@/layouts/components/HelperTooltip'
import Sources from '@/custom/class/Enum/Sources.js'
import VSelect from 'vue-select'
import DefaultAgent from '@/layouts/components/Transmission/Middleware/Agent/DefaultAgent';
import CustomInputList from '@/layouts/components/Transmission/Middleware/CustomInputList.vue';
import KeyValue from "@/views/pages/middleware/MiddlewareManagement/Components/Requester/KeyValueItems.vue";

import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-markup.js';
// import 'prismjs/components/prism-xml-doc.js';
import 'prismjs/components/prism-json.js';
// import 'prismjs/components/prism-sql.js';
import 'prismjs/themes/prism-tomorrow.css';

import ConnectionRegisterInput from '@/views/pages/middleware/MiddlewareManagement/Components/ConnectionRegisterInput.vue'
import MiddlewaresMixin from '@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewaresMixin.js'
import Middlewares from "@/custom/class/Enum/Middlewares.js"
import MiddlewareSidebarOutline from "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareSidebarOutline.vue"
import * as MiddlewareFunctions from  "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareFunctions.js"
import Monaco from "@/layouts/components/editor/monaco.vue"


  export default {
    mixins: [MiddlewaresMixin],    
    components: {
      BPopover,
      BSidebar,
      BIcon,
      BButton,
      BRow,
      BCol,
      BInputGroup,
      BInputGroupAppend,
      BFormInput,
      BContainer,
      VuePerfectScrollbar,
      VariablesPanel,
      BSpinner,
      BTabs,
      BTab,
      BCardText,
      BCard,
      BAvatar,
      BCardBody,
      ActionsReorderList,
      PrismEditor,
      CustomInput,
      BSkeleton,
      HelperTooltip,
      VSelect,
      CustomInputList,
      KeyValue,
      BFormCheckbox,
      BCollapse,
      ConnectionRegisterInput,
      MiddlewareSidebarOutline,
      Monaco,
    },
    props: {
      i18nKey: {
        type: String,
        default: "mongo_update"
      },
      middlewareID: {
        type: Number,
        required: true,
      },
      uuid: {
        type: String,
        required: true,
      },
      value: {
        type: Array,
        required: true,
      }
    },
    data() {
      return {
        uuidMap: {},
        variables: undefined,
        variant_map: undefined,
        middlewareType: new Middlewares().items.find(el=> el.id == 35),

        agentList: [],
        isSaving: false,
        version: 0,
        changingPosition: undefined,
        changerSelection: null,
        selectChangerOptions: undefined,

        defaultAgentActionID: 3,
        defaultAgentBlockID: 1,

        uri_active: false,
        
        renderCustomInputList: [
          // { label: 'database',  cols: "6"  , discard_if_uri: false},
          { label: 'collection',  cols: "6"  , discard_if_uri: false},
          // { label: 'username',  cols: "6"  , discard_if_uri: true},
          // { label: 'password',  cols: "6"  , discard_if_uri: true},
          // { label: 'host',  cols: "6"  , discard_if_uri: true},
          // { label: 'port',  cols: "6"  , discard_if_uri: true},
        ],

        mongoUpdateData: {
          // username: '',
          // password: '',
          // database: '',
          collection: '',
          document: '',
          filter: '',
          // host: '',
          // port: '',
          // tls: '',
          // certificate: '',
          // uri : '',
          connection: ''
        },

        fieldName:{
          username: 'USERNAME',
          password: 'PASSWORD',
          database: 'DATABASE',
          collection: 'COLLECTION',
          document: 'DOCUMENT',
          filter: 'FILTER',
          host: 'HOST',
          port: 'PORT',
          tls: 'TLS',
          certificate: 'CERTIFICATE',
          uri : 'URI',
          connection: 'CONNECTION'
        },

        defaultSource: {
          username: 7,
          password: 7,
          database: 7,
          collection: 7,
          document: 7,
          filter: 7,
          host: 7,
          port: 7,
          tls: 7,
          certificate: 7,
          uri : 7,
          connection: 7
        },

        defaultValue: {
          username: '',
          password: '',
          database: '',
          collection: '',
          document: '',
          filter: '',
          host: '',
          port: '27017',
          tls: false,
          certificate: '',
          uri : '',
          connection: ''
        },
      }
    },
    computed: {
      agents: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
    },
    mounted() {
      this.init();
    },
    methods: {
      init() {
        this.initializeAgents();
      },
      highlighter(code) {
        return highlight(code, languages.json); // languages.<insert language> to return html with markup
      },
      configureVars() {
        this.variant_map = this.$refs[this.getID("variables-panel")].getVariantMap();
        this.variables = this.$refs[this.getID("variables-panel")].getAllVars();
      },
      isObject(item) {
        return item instanceof Object && !(item instanceof Array);
      },
      isArray(item) {
        return item instanceof Array;
      },
      buildAgents() {
        this.isSaving = true;

        Object.keys(this.mongoUpdateData).forEach((key) => {
          if (this.mongoUpdateData[key].register_1.value !== '' && this.mongoUpdateData[key].register_1.value !== undefined && this.mongoUpdateData[key].register_1.value !== null ) {
            if (key=='tls'){
              let r = structuredClone(this.mongoUpdateData.tls)
              r.register_1.value = String(r.register_1.value)
              this.agentList.push(DefaultAgent.defineToAPI(r, this.middlewareID));
            } else {
              this.agentList.push(DefaultAgent.defineToAPI(this.mongoUpdateData[key], this.middlewareID));
            }
          }
        })
        
        this.saveAgents();
      },
      saveAgents() {
        this.$store
          .dispatch("saveAgents", {
            agentList: this.agentList,
            transmissionID: this.$route.params.transmissionID,
          })
          .then((response) => {
            makeToast({
              title: this.$t("agent.toast.create_agents.success.title"),
              text: this.$t("agent.toast.create_agents.success.message"),
              variant: "success",
              icon: "CheckIcon",
            });
            this.$emit("saved", response.data);

          })
          .catch((error) => {
            console.error(error)
            this.isSaving = false;
          });
      },
      itemAdded() {
        this.version++;
      },
      initializeAgents() {
        Object.keys(this.mongoUpdateData).forEach((pos) => {
          this.mongoUpdateData[pos] = MiddlewareFunctions.getValueFrom(
            {
              default: DefaultAgent.setAgent(this, {
                id: null,
                enum_agent_action_id: this.defaultAgentActionID,
                enum_agent_block_id: this.defaultAgentBlockID,
                register_1: {
                  source: this.defaultSource[pos],
                  value: this.defaultValue[pos],
                },
                register_2: {
                  source: null,
                  value: null,
                },
                register_destiny: {
                  source: 9,
                  value: this.fieldName[pos],
                },
                execution_order: 1,
                fatal_on_fail: true,
              }),
              source: 9,
              value: this.fieldName[pos],
              from: {
                value: "register_destiny",
                source: "register_destiny",
              },
              modifier: false,
              multiple: false,
              expected: {
                source: "7",
                from: "register_1",
              },
            },
            this.agents
          );
        })

          // if (this.mongoUpdateData.tls.register_1.value == 'false'){
          //   this.mongoUpdateData.tls.register_1.value == false
          // }
          // if (this.mongoUpdateData.tls.register_1.value == 'true'){
          //   this.mongoUpdateData.tls.register_1.value == true
          // }
        
          // if (this.mongoUpdateData.uri.register_1.value.length){
          //   this.uri_active = true
          // }
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      sidebarShown() {
this.$emit("shown")
        this.isSaving = false;

        let el = document.getElementsByTagName("html");
        el[0].classList.add("hide-scrollbar");
      },
      sidebarHidden() {
        this.$emit("close");

        let el = document.getElementsByTagName("html");
        el[0].classList.remove("hide-scrollbar");
      },
      toggleSidebar() {
        this.$root.$emit("bv::toggle::collapse", this.uuid);
      },      
      copyCertificateToClipboard(){
        let certificate = this.mongoUpdateData.certificate.register_1.value
        navigator.clipboard.writeText(certificate)
        makeToast({
          title: this.$t("common.toast.copy_to_clipboard.success.title"),
          text: this.$t("common.toast.copy_to_clipboard.success.message"),
          variant: "success",
          icon: "CheckIcon",
        });
      }
    }

  }
</script>


<style lang="scss" scoped>

  .sidebar-container{
    max-height: 97vh !important;
    height: fit-content !important;
    overflow-y: auto !important;
  }
  
  .sidebar-fixed-header{
    position: sticky !important;
    top: 0 !important;
    z-index: 2;
  }
  
  .sidebar-content{
    position: relative !important;
    padding-bottom: 20px;
    margin-bottom: 70px !important;
  }
  
</style>

<style lang="scss" scoped>
.agent-text {
  display: block !important;
  width: 300px;
}

.my-editor {
  background: #161d31;
  color: #ccc;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;

  pre, textarea {
    background: none !important;
    border: none !important;
    outline: none !important;

    &:focus, &:active, &:focus-visible {
      border: none !important;
      outline: none !important;
    }
  }
}

.height-300 {
  height: 250px !important;
}

</style>
<style lang="scss">
.bg-default,
.b-sidebar-header {
  background-color: #151925 !important;
}

.agent-sidebar {
  .b-sidebar-right {
    border-left: solid 1px #0d111c !important;
  }

  .b-sidebar-body {
    overflow: hidden !important;
  }

  .control-height {
    position: relative;
    overflow: auto;

    min-height: 100%;
    padding-bottom: 50px;
  }

  .limit-height {
    height: calc(100% - 30%) !important;
  }

  .saving-adjust-text-position {
    position: relative;
    top: 2px;
  }
}

</style>