<template>
  <div class="connection-container">

    <div class="d-flex justify-content-between align-items-center ">
      <div>
        <span class="mx-25 small">Connection</span>
        
      </div>
      <b-button class="p-0 pb-25" variant="none" @click="$refs['create-connection-modal'].show()">
        <span class="small add-connection-btn text-dark">
          <b-icon icon="plus" variant="favorite" scale="1.3"/> Add Connection
        </span>
      </b-button>
    </div>

    <v-select 
      v-if="connectionsOrdered" 
      :getOptionLabel="el=>el.name"  
      :reduce="el=> el.identifier || ''"
      :clearable="false"  
      :options="connectionsOrdered" 
      class="z-index-1"
      v-model="agent.register_1.value"  
      @input="putNameOnReg2"
    >
      <template #option="option">
        <div  v-if="option.isDinamic" align="center" class="variable-connect-option">
           Connect with Variables
        </div>
        
        <div v-else class="d-flex justify-content-between align-items-center" :class="String(option.enum_connection_type_id)!=String(connectionTypeID) ? 'low-opacity' : ''">
          <div>
            <span class="font-weight-bolder"> {{option.name}}</span>
          </div>

          <div v-if="agent.register_1 && agent.register_1.value != option.identifier"> 
            <b-badge variant="light-info" class="text-black mx-50">{{$t(getConnectionType(option.enum_connection_type_id).label)}}</b-badge>
          </div>  

        </div>
      </template>

      <template #selected-option="option">
        <div v-if="option.isDinamic" class="text-favorite">
          Connecting with <b>Variables</b>
        </div>
        <div class="d-flex justify-content-between align-items-center" v-else>
          <div>
            <span class="font-weight-bolder"> {{option.name}}</span>
          </div>
        </div>
      </template>

    </v-select>
    
    <b-collapse :visible="agent.register_1.value == 'DINAMIC'" appear>
      <div class="py-1">
        <div class="text-dark">
          Set the corresponding data on the following sources:
        </div>
        <div class="w-fit-content border-left-info pl-25">
          
          <div 
            v-for="field in getConnectionType(connectionTypeID).fields" 
            :key="'connection-field-'+field.name" 
            class="d-flex align-items-center justify-content-center my-25 mx-25 bg-dark-blue py-25 px-50 rounded"
          >
            <span class="text-info">{{field.name}} </span> 
            <b-icon icon="arrow-right-short" class="mx-25"/>
            <span class="text-success">{{field.source}}</span> 
          </div>
          
        </div>
      </div>
    </b-collapse>

    <create-connection-modal ref="create-connection-modal"/>
  </div>
</template>

<script>

import {
   BFormInput, 
   BFormGroup, 
   BForm, 
   BBadge, 
   BModal,
   BFormCheckbox,
   BSkeleton,
   BRow,
   BCol,
   BButton,
   BCollapse,
} from 'bootstrap-vue'

import HelperTooltip from "@/layouts/components/HelperTooltip.vue";
import VSelect from "vue-select";
import { mapGetters } from "vuex";
import ConnectionTypes from '@/custom/class/Enum/ConnectionTypes';
import CreateConnectionModal from '@/views/pages/middleware/MiddlewareManagement/Components/Modals/CreateConnectionModal.vue';

  export default {
    components: {
      BFormInput, 
      BFormGroup, 
      BForm, 
      BBadge, 
      BModal, 
      BFormCheckbox,
      HelperTooltip,
      BSkeleton,
      VSelect,
      BRow,
      BCol,
      BButton,
      CreateConnectionModal,
      BCollapse,
    },
    data() {
      return {
      }
    },
    props: {
      value: {
        type: Object,
        required: true 
      },
      connectionTypeID:{
        type: Number,
        required: true,
      }
    },
    computed: {
		  ...mapGetters('connection' , ['getConnections']),
      agent: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      organizationID(){
        return localStorage.getItem('selectedOrganization')
      },
      connectionsOrdered(){
        let items = this.getConnections

        if (!Array.isArray(items)){
          return undefined
        }
        let typeIDs = []
        
        new ConnectionTypes().items.forEach((el)=>{
          typeIDs.push(el.id)
        })

        let idx = typeIDs.findIndex(el => el == this.connectionTypeID)
        
        if (idx>-1){
          typeIDs.splice(idx,1)
        }

        typeIDs.unshift(this.connectionTypeID)

        let r = []
      
        typeIDs.forEach((type)=>{
          r.push(...items.filter(el=>String(el.enum_connection_type_id) == String(type)))
        })    
        r.push({
          identifier: "DINAMIC",
          label:"dinamic",
          isDinamic: true,
        })
      
        return r
      }
    },
    mounted () {
      this.init();
    },
    methods: {
      init() {
        this.$store.dispatch('connection/getOrgConnections', {organization: this.organizationID})
      },
      getConnectionType(id = undefined){
        let types = new ConnectionTypes().items
        if (id){
          return types.find(el => el.id == id )
        } else {
          return types
        }
      },
      putNameOnReg2(identifier){
        let connections = structuredClone(this.getConnections)
        // if (!connections || !identifier){
        //   this.agent.register_1 = {}
        //   return
        // }
        let idx = connections.findIndex(el => el.identifier == identifier)
        if (idx > -1){
          let name = connections[idx].name
          this.agent.register_2.value = name
          this.agent.register_2.source = 7
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
.connection-container{
  padding-bottom: 18px;
  //this is so the component occupies the same height as a CustomInput
}
.z-index-1{
  z-index: 1;
}
.add-connection-btn{
  border-bottom: 1px solid var(--favorite);
  font-weight: bold;
  opacity: 0.65;
  font-size: 10px;
}
.low-opacity{
  opacity: 0.25;
}

.variable-connect-option{
  font-size: 13px;
  color: #6e7383b7;
  font-weight: bold;
}
</style>